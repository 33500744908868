import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AppComponentBase } from 'src/app/core/utils/base-components/app-component-base';

@Component({
  selector: 'app-root',
  templateUrl: './unauthorised.component.html',
})
export class UnauthorisedComponent extends AppComponentBase implements OnInit, OnDestroy {
  
  constructor(private router: Router) {
    super();

    let sessionSubscription = this.appSessionService.sessionStateObservable().subscribe(s => {
      this.sessionInfo = s;
          this.loadPageData();
      // Set if the session is impersonated
      this.isImpersonatedSession = this.sessionInfo.originalUserSessionState !== undefined;
  });
  this.sessionStateSubscription.add(sessionSubscription);
  }

  public override ngOnInit(): void {
    super.ngOnInit();

    let homePageSetting = this.sessionInfo.currentAsset?.assetSettingModels.find((e) => e.name.toLowerCase() === 'defaulthomepage')?.value;
    if (homePageSetting == "redirect") {
      let navigationProperties = ['main', this.sessionInfo.currentTenant == null ? '' : this.sessionInfo.currentTenant.tenancyName, this.sessionInfo.currentAsset?.name];
      navigationProperties.push('redirect');
      this.router.navigate(navigationProperties);
    }
    
  }

  public override ngOnDestroy(): void {
    this.logDebug(this.ngOnDestroy.name, 'Unauthorised OnDestroy');
    // Unsubscribe from all subscriptions
    this.sessionStateSubscription.unsubscribe();
    this.pageSubscriptions.unsubscribe();

    super.ngOnDestroy();
}
}
